export const api = async (endpoint, appId, token, body, method = 'POST') => {
  const headers = {
    'Content-Type': 'application/json',
    'X-Parse-Application-Id': appId || 1,
    'X-Parse-Session-Token': token || ''
  }

  const appendant = {
    method,
    mode: 'cors',
    headers,
    body
  }

  let response = await fetch(endpoint, appendant)
  return response.json()
}
